@font-face {
  font-family: 'News Gothic Medium', Fallback, sans-serif;
  src: url('/assets/themes/provincie-utrecht/fonts/provincie-utrecht/News-Gothic-Std-Medium.woff')
    format('woff');
}

@font-face {
  font-family: 'News Gothic Bold', Fallback, sans-serif;
  src: url('/assets/themes/provincie-utrecht/fonts/provincie-utrecht/News-Gothic-Bold.woff')
    format('woff');
}

$utrecht-red: #e5352c;
$utrecht-dark-red: #a10000;
$utrecht-black: #000000;
$utrecht-white: #ffffff;
$utrecht-blue: #0098d4;
$utrecht-green: #83b81a;
$utrecht-orange: #f39910;
$utrecht-gray: #ebebeb;
$utrecht-dark-gray: #666666;
$utrecht-turquoise: #56babe;
$utrecht-dark-turquoise: #006b6f;
$utrecht-font-family: 'News Gothic Medium', Fallback, sans-serif;
$utrecht-font-family-bold: 'News Gothic Bold', Fallback, sans-serif;

/**
 * Bootstrap customization
 */

$theme-colors: (
  'primary': $utrecht-dark-gray,
  'secondary': $utrecht-blue,
  'success': $utrecht-green,
  'info': $utrecht-dark-turquoise,
  'warning': $utrecht-orange,
  'danger': $utrecht-red,
  'light': $utrecht-gray,
  'dark': $utrecht-black,
);

$font-family-sans-serif: $utrecht-font-family;
$body-bg: $utrecht-white;

$headings-color: $utrecht-red;
$headings-font-family: $utrecht-font-family-bold;

$link-color: $utrecht-dark-gray;

$pagination-active-bg: $utrecht-dark-gray;
$pagination-active-border-color: $pagination-active-bg;
$pagination-focus-box-shadow: none;

$code-color: $utrecht-red;

@import 'bootstrap/scss/bootstrap';

body {
  background-color: $utrecht-gray;
}

.navbar {
  background-color: $utrecht-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

@media (max-width: 991.98px) {
  .nav-header {
    font-size: 1em;
  }
}

@media (max-width: 760px) {
  .nav-header {
    font-size: 0.75em;
  }
}

a.nav-link {
  color: $utrecht-black;
  font-size: small;
  text-transform: uppercase;
}

/**
 * Highcharts customization
 */

@import 'highcharts/css/highcharts';

:root,
.highcharts-light,
.highcharts-dark {
  /* Colors for data series and points. */
  --highcharts-color-0: #{$utrecht-red};
  --highcharts-color-1: #{$utrecht-dark-red};
  --highcharts-color-2: #{$utrecht-dark-gray};
  --highcharts-color-3: #{$utrecht-green};
  --highcharts-color-4: #{$utrecht-blue};
  --highcharts-color-5: #{$utrecht-orange};
  --highcharts-color-6: #{$utrecht-turquoise};
  --highcharts-color-7: #{$utrecht-gray};
  --highcharts-color-8: #{$utrecht-dark-turquoise};
}

/* Add shadow to column data labels to ensure enough contrast with column background */
.highcharts-column-series,
.highcharts-column-series {
  .highcharts-data-label {
    span,
    text {
      font-size: 0.75rem;
      text-align: center;
      text-shadow:
        -1px -1px 0 $utrecht-black,
        1px -1px 0 $utrecht-black,
        -1px 1px 0 $utrecht-black,
        1px 1px 0 $utrecht-black;
    }

    span {
      color: $utrecht-white;
    }

    text {
      fill: $utrecht-white;
    }
  }
}

.highcharts-title {
  font-size: 1rem;
  font-weight: normal;
}

.highcharts-legend-item text {
  font-size: 0.75rem;
}
